<template>
  <div class="meeting">
    <div class="meeting__top">
      <meeting-indicator v-if="!app.state.matches('app.view.meeting.lobby')" />
      <a-spacer />
      <meeting-zoom
        :controls="user.isAssistant"
        :disabled="session.notClientCamera && app.state.matches('app.view.meeting.call')"
        class="pte--auto me--2"
      />
      <meeting-device-control class="pte--auto me--2" />
      <meeting-participants class="pte--auto" />
    </div>
    <div v-if="state.matches('participants.visible')" class="meeting__overlay"></div>

    <lobby-view v-if="app.state.matches('app.view.meeting.lobby')" />
    <call-view v-if="app.state.matches('app.view.meeting.call')" />
    <draw-view v-if="app.state.matches('app.view.meeting.draw')" />
    <screensharing-view v-if="app.state.matches('app.view.meeting.screensharing')" />
    <!--<a-board v-if="app.state.matches('app.view.meeting.call') || app.state.matches('app.view.meeting.draw')" v-show="app.state.matches('app.view.meeting.draw')"/>-->
    <template v-if="!app.state.matches('app.view.meeting.lobby')">
      <meeting-board-client v-if="user.isClient" :over="app.state.matches('app.view.meeting.call') || app.state.matches('app.view.meeting.screensharing')" />
      <template v-else>
        <meeting-board :over="app.state.matches('app.view.meeting.call') || app.state.matches('app.view.meeting.screensharing')" />
        <meeting-overlay />
        <meeting-drop-area />
      </template>
    </template>

    <Transition name="fade">
      <meeting-notification v-if="notification.hasMessage" />
    </Transition>
    
    <TransitionGroup name="slide-left">
      <a-toast v-if="state.matches('roleExchange.waitingForCounterpartyResponse')" type="info" :action="$t('toast.action.cancel')" loading overlay @action="roleExchangeProcedure.cancel">{{ $t('roleExchange.waitingMessage') }}</a-toast>
      <a-toast v-if="state.matches('roleExchange.counterpartyAcceptedOffer')" type="success">{{ $t('roleExchange.acceptedMessage') }}</a-toast>
      <a-toast v-if="state.matches('roleExchange.counterpartyDeniedOffer')" type="danger">{{ $t('roleExchange.deniedMessage') }}</a-toast>
      <a-toast v-if="state.matches('roleExchange.offerCanceled') && roleExchangeProcedure.context.counterparty.party === 'assistant'" type="info">{{ $t('roleExchange.canceledMessage') }}</a-toast>
      <a-toast v-if="state.matches('roleExchange.partyRoleChanged')" type="info">{{ $t('roleExchange.changedRoleMessage', { name: roleExchangeProcedure.context.role }) }}</a-toast>
    </TransitionGroup>
    
    <Transition name="fade">
      <a-modal
        v-if="state.matches('roleExchange.waitingForPartyResponse')"
        :title="$t('roleExchange.modalTitle')"
        @close="roleExchangeProcedure.denyOffer"
        closable
      >
        <p v-if="state.matches('roleExchange.waitingForPartyResponse')">{{ $t('roleExchange.offerMessage', { role: roleExchangeProcedure.context.counterparty.party }) }}</p>
        <template #footer>
          <a-button variant="outline-primary" @click="roleExchangeProcedure.denyOffer">{{ $t('roleExchange.denyMessage') }}</a-button>
          <a-button variant="primary" @click="roleExchangeProcedure.acceptOffer">{{ $t('roleExchange.acceptMessage') }}</a-button>
        </template>
      </a-modal>
    </Transition>

    <meeting-tools />
    <Transition name="slide-right">
      <meeting-chat v-show="state.matches('chat.visible')" />
    </Transition>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import {
  useAppStore,
  useUserStore,
  useSessionStore,
  useNotificationStore
} from '@/stores'
import { useMeetingMachine } from '@/state'
import container from '@di'
import { useWearML } from '@/composables'
import { useWakeLock } from '@vueuse/core'

const app = useAppStore()
const user = useUserStore()
const session = useSessionStore()
const notification = useNotificationStore()
const wearML = useWearML()
const { request } = useWakeLock()

const { state, send } = useMeetingMachine()
const roleExchangeProcedure = container.procedures.get('roleExchangeProcedure')

onMounted(() => {
  request('screen')
  wearML.refresh()
})
</script>

<style lang="scss">
.meeting {
  &__top {
    display: flex;
    position: fixed;
    width: 100%;
    padding: $box-spacer;
    z-index: ($zindex-overlay + 2);
    pointer-events: none;
  }
  &__overlay {
    position: fixed;
    left: 0; top: 0; right: 0; bottom: 0;
    z-index: $zindex-overlay;
  }
}
</style>
