<template>
  <a-layout class="video-local">
    <video
      id="local-video"
      ref="video"
      class="media media--contain"
      autoplay
      muted
      playsinline
    ></video>
    <a-announcement v-if="app.state.matches('app.camera.notAvailable')" icon="camera-off" :message="$t('announcement.cameraNotAvailable')" />
  </a-layout>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useTransformStore, useAppStore } from '@/stores'
import { useLocalStream } from '@/composables'

const props = defineProps({ mode: String })

const app = useAppStore()
const transform = useTransformStore()
const { video: localVideoStream } = useLocalStream()

const video = ref(null)
const { zoom, x, y } = storeToRefs(transform)

watchEffect(() => {
  if (video?.value) {
      video.value.srcObject = localVideoStream.value    
  }
})
</script>

<style lang="scss">
$video-bg: $black;

.video-local {
  background-color: $video-bg;
  z-index: $zindex-video;
  touch-action: none;
  video {
    transition-property: transform;
    transition-duration: $duration-fast;
    transform-origin: 0 0;
    transform: matrix(v-bind(zoom), 0, 0, (v-bind(zoom), v-bind(x), v-bind(y)));
  }
}
</style>
