<template>
  <div class="participants">
    <a-button-box
      :active="state.matches('participants.visible')"
    >
      <a-button
        ref="toggle"
        variant="link-primary"
        @click="toggleHandler"
      >
        <a-icon name="people" />
        <a-badge :value="session.users.length" class="ms--1" />
      </a-button>
    </a-button-box>
    <Transition name="fade">
      <div 
        class="box mb--0 mt--1"
        v-show="state.matches('participants.visible')"
        ref="target"
      >
        <div class="box__header">
          <h2>{{ $t('participants.title') }}</h2>
          <a-button 
            v-if="user.isAssistant"
            variant="link-primary"
            class="p--0 "
            @click="addMemberHandler">
            <a-icon name="plus-circle" />
          </a-button>
        </div>
        <div class="box__content">
          <template v-for="role in roles" :key="role">
            <template v-if="members.some((item) => item.party === role)">
              <h3 class="text--uppercase fs--xs">{{ $t(`participants.subtitle.${role}`) }}</h3>
              <ul class="participants__list">
                <li
                  v-for="member in members.filter((item) => item.party === role)"
                  :class="[
                    member.status === 'online' && 'is--active',
                    'py--1'
                  ]"
                  :key="member.id"
                >
                  <div class="participants__avatar me--2">
                    {{ member.fullname.charAt(0) }}
                  </div>
                  <div class="participants__name me--4">
                    <template v-if="user.isAssistant && member.fullname === 'Guest' && member.contact">
                      {{ member.contact }}
                    </template>
                    <template v-else>
                      {{ member.fullname }}
                    </template>
                  </div>
                  <div v-if="user.id !== member.id" class="participants__action ms--auto">
                    <template v-if="member.status === 'online'">
                      <a-button
                        v-if="user.can('exchangeRole') && member.party === 'observer'"
                        variant="link-primary"
                        class="px--1"
                        @click="roleExchangeProcedure.offerRole(member)"
                      >
                        <a-icon name="swap" />
                      </a-button>
                      <a-button
                        v-if="member.profile?.camera"
                        :variant="`link-${member.profile.camera === 'not-available' ? 'danger' : 'primary'}`"
                        class="px--1"
                        disabled
                      >
                        <a-icon :name="`camera${member.profile.camera === 'on' ? '' : '-off'}`" />
                      </a-button>
                      <a-button
                        v-if="member.profile?.microphone"
                        :variant="`link-${member.profile.microphone === 'not-available' ? 'danger' : 'primary'}`"
                        class="px--1"
                        :disabled="!user.can('muteParticipants')"
                        @click="muteHandler(member.id)"
                      >
                        <a-icon :name="`microphone${member.profile.microphone === 'on' ? '' : '-off'}`" />
                      </a-button>
                    </template>
                    <a-button
                      v-else-if="notificationProcedure.hasNotificationMethod(member.id)"
                      variant="link-primary"
                      @click="notificationProcedure.notify(member.id)"
                    >
                      <div v-if="notificationProcedure.state.value[member.id] === 'pending'" class="a-loader__icon a-loader__icon--md">
                        <div class="a-loader__icon__content"></div>
                      </div>
                      <a-icon v-else-if="notificationProcedure.state.value[member.id] === 'success'" class="text--success" name="check-circle" />
                      <a-icon v-else-if="notificationProcedure.state.value[member.id] === 'error'" class="text--danger" name="exclamation-mark-circle" />
                      <a-icon v-else name="bell" />
                    </a-button>
                  </div>
                </li>
              </ul>
            </template>
          </template>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import container from '@di'
import { ref, computed } from 'vue'
import { useSessionStore, useUserStore, useAppStore } from '@/stores'
import { useBroadcast } from '@/composables'
import { participant } from '@/messages'
import { useMeetingMachine } from '@/state'
import { onClickOutside } from '@vueuse/core'

const app = useAppStore()
const session = useSessionStore()
const user = useUserStore()
const broadcast = useBroadcast()
const { state, send } = useMeetingMachine()
const roleExchangeProcedure = container.procedures.get('roleExchangeProcedure')
const notificationProcedure = container.procedures.get('notificationProcedure')
const toggle = ref(null)
const target = ref(null)

const members = computed(() => Object.values([
  ...session.info.participants,
  ...session.users
].reduce((acc, obj) => {
  acc[obj.id] = obj
  return acc
}, {})))

const roles = ['client', 'assistant', 'observer']

function toggleHandler () {
  if (state.value.matches('participants.visible')) {
    send('HIDE_PARTICIPANTS')
  } else {
    send('SHOW_PARTICIPANTS')
  }
}

function muteHandler(id) {
  if (user.can('muteParticipants')) {
    if (Number(user.id) === Number(id)) {
      user.profile.microphone === 'muted' ? user.unmute('audio') : user.mute('audio')
    } else {
      broadcast(participant.muteMessage(id, 'audio'))
    }
  }
}

function addMemberHandler () {
  const { conference: callId } = session
  const { configuration: { session: { detailUrl }}} = app
  const url = detailUrl.replace('{{callId}}', callId)
  window.open(url, '_blank')
  
}

onClickOutside(target, (event) => {
  if (state.value.matches('participants.visible') && event.target !== toggle.value.$el) {
    send('HIDE_PARTICIPANTS')
  }
})
</script>

<style lang="scss">
$participants-opacity: 0.4;
$participants-avatar-size: 2rem;
$participants-avatar-bg: $purple-200;
$participants-avatar-color: $primary;
$participants-max-width: calc(100vw - #{$box-spacer * 2});
$participants-name-max-width: 10rem;

.participants {
  position: relative;
  .box {
    position: absolute;
    top: 100%;
    right: 0;
    max-width: $participants-max-width;
    max-height: calc(100dvh - #{$component-height + $box-spacer + map-get($spacers, 2)} - 3.625rem); // TODO: tools height variable
    overflow: auto;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 0; left: 0;
      width: 100%;
      padding-bottom: $box-spacer;
      z-index: 1;
      .btn {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;  
    width: 2rem;
    height: 2rem;
    background-color: $participants-avatar-bg;
    color: $participants-avatar-color;
    text-transform: uppercase;
    border-radius: 50%;
  }
  &__name {
    max-width: $participants-name-max-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .is--active & {
      opacity: 1;
    }
  }
  &__avatar,
  &__name {
    transition-property: opacity;
    transition-duration: $duration-fast;
    opacity: $participants-opacity;
    .is--active > & {
      opacity: 1;
    }
  }
  &__action {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: $breakpoint-sm) {
    .box {
      width: $participants-max-width;
    }
  }
}
</style>
