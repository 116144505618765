import { logger } from '../../logger/src'
import messenger from "./Messenger"
export default class Connection {
  constructor (strategy) {
    this.strategy = strategy
    this.isReady = false
    this.isolated = false
    this.isolatedReadyCallback = null
    this.queue = []
    return this
  }

  initialize () {
    this.attachListeners()
    this.strategy
      .subscribe('strategy:ready', this.handleReady)
      .subscribe('strategy:counterpartyReady', this.handleCounterpartyReady)
      .subscribe('strategy:data', this.handleData)
      .subscribe('strategy:data:progress', this.handleDataProgress)
      .subscribe('strategy:stream:audio', this.handleAudioStream)
      .subscribe('strategy:stream:video', this.handleVideoStream)
      .subscribe('strategy:error', this.handleError)
      .subscribe('strategy:replaceTrack:audio', this.handleReplaceAudioTrack)
      .subscribe('strategy:replaceTrack:video', this.handleReplaceVideoTrack)
      .subscribe('strategy:connections', this.handleConnections)
      .subscribe('strategy:screensharingStarted:presenter', this.handlePresenterScreensharingStarted)
      .subscribe('strategy:screensharingStarted:viewer', this.handleViewerScreensharingStarted)
      .subscribe('strategy:recording:started', this.handleRecordingStarted)
      .subscribe('strategy:recording:stopped', this.handleRecordingStopped)
      .subscribe('strategy:notification', this.handleNotification)
      .initialize()
    return this
  }

  isolate = (isolatedReadyCallback) => {
    this.isolatedReadyCallback = isolatedReadyCallback
    this.isolated = true
    return this
  }

  release = async () => {
    this.isolated = false

    for (const item of this.queue) {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.emit(item.channel, item.data)
          resolve()
        }, 50)
      })
    }
  }

  emit = (channel, data) => {
    if (this.isolated) {
      this.queue.push({ channel, data})
      if (channel === 'strategy:ready') {
        this.isolatedReadyCallback()
      }
      return
    }
    messenger.emit(channel, data)
  }

  attachListeners = () => {   
    messenger.subscribe(`connection:${this.strategy.name}:replaceTrack`, 'stream:replaceTrack:audio', this.replaceAudioTrack)
    messenger.subscribe(`connection:${this.strategy.name}:replaceTrack`, 'stream:replaceTrack:video', this.replaceVideoTrack)
    messenger.subscribe(`connection:${this.strategy.name}:startScreensharing`, ['screensharing:start:presenter', 'screensharing:start:viewer'], this.startScreensharing)
    messenger.subscribe(`connection:${this.strategy.name}:stopScreensharing`, 'screensharing:stop', this.stopScreensharing)
    messenger.subscribe(`connection:${this.strategy.name}:startRecording`, 'recording:start', this.startRecording)
    messenger.subscribe(`connection:${this.strategy.name}:stopRecording`, 'recording:stop', this.stopRecording)
  }

  replaceAudioTrack = ({ oldTrack, newTrack, stream}) => {
    this.strategy.replaceTrack('audio', oldTrack, newTrack, stream)
  }

  replaceVideoTrack = ({ oldTrack, newTrack, stream}) => {
    console.warn('connection replace track')
    this.strategy.replaceTrack('video', oldTrack, newTrack, stream)
  }

  startScreensharing = (data, channel) => {
    if (this.strategy.name === 'p2p') {
      if (channel === 'screensharing:start:presenter') {
        this.strategy.createPresenterScreensharingPeer(data?.stream)
      } else if (channel === 'screensharing:start:viewer') {
        this.strategy.createViewerScreensharingPeer()
      }
    } else {
      if (channel === 'screensharing:start:presenter') {
        this.strategy.createPresenterScreensharingEndpoint(data?.stream)
      } else if (channel === 'screensharing:start:viewer') {
        this.strategy.createViewerScreensharingEndpoint()
      }
    }
  }

  stopScreensharing = () => {
    this.strategy.destroyScreensharing()
  }

  startRecording = (data) => {
    const { conference } = data
    this.strategy.startRecording(conference)
  }

  stopRecording = () => {
    this.strategy.stopRecording()
  }

  handleReady = (event) => {
    const { type: channel } = event
    this.isReady = true
    this.emit(channel)
  }

  handleCounterpartyReady = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleData = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleDataProgress = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleVideoStream = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleAudioStream = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleConnections = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handlePresenterScreensharingStarted = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleViewerScreensharingStarted = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleRecordingStarted = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleRecordingStopped = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleError = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleReplaceAudioTrack = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleReplaceVideoTrack = (event) => {
    const { type: channel, data } = event
    this.emit(channel, data)
  }

  handleNotification = (event) => {
    const { type: channel, data } = event
    messenger.emit(channel, data)    
  }

  broadcast = (data) => {
    if (this.isReady) {
      this.strategy.broadcast(data)
    } else {
      logger('communication', 'warn', 'skipped broadcast, connection doesn\'t seem to be estabilished', { data })
    }
  }

  destroy = () => {
    messenger.unsubscribe(`connection:${this.strategy.name}:replaceTrack`)
    messenger.unsubscribe(`connection:${this.strategy.name}:replaceTrack`)
    messenger.unsubscribe(`connection:${this.strategy.name}:startScreensharing`)
    messenger.unsubscribe(`connection:${this.strategy.name}:stopScreensharing`)
    messenger.unsubscribe(`connection:${this.strategy.name}:startRecording`)
    messenger.unsubscribe(`connection:${this.strategy.name}:stopRecording`)
    return this.strategy.destroy()
  }
}
