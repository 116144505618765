import JsonRpc from './JsonRpc.js'
import { MCUStrategy, P2PStrategy } from './strategies/index.js'
import Connection from './Connection.js'
import Repository from './Repository.js'
import container from './di.js'

export default class Mediator {
  constructor (configuration, agent) {
    container.jsonrpc = new JsonRpc(configuration)
    this.configuration = configuration
    this.agent = agent
    this.strategies = {}
    this.repository = null
    this.connection = null
    this.standbyConnection = null
    this.addStrategy(new MCUStrategy())
    this.addStrategy(new P2PStrategy())
  }

  addStrategy (strategy) {
    this.strategies[strategy.name] = strategy
    container.jsonrpc.addConfiguration(strategy.configuration)
  }

  call = () => {
    const strategies = new Map([
      ['mcu', this.strategies.mcu],
      ['p2p', this.strategies.p2p]
    ])
    const strategyHandler = strategies.get(container.session.strategy)

    if (strategyHandler !== undefined) {
      this.connection = new Connection(strategyHandler).initialize()
      this.repository = new Repository(this.configuration)
    }

  }
  
  prepareStrategy = (strategy) => {
    if (this.strategies[strategy] !== undefined) {
      return new Promise((resolve) => {
        this.standbyConnection = new Connection(this.strategies[strategy])
          .isolate(() => {
            resolve()
          })
          .initialize()
      })
    }
  }

  changeStrategy = () => {
    if (this.standbyConnection) {
      this.connection.destroy()
      setTimeout(() => {
        this.connection = this.standbyConnection
        this.connection.release()
        delete this.standbyConnection
      }, 1000)
    }
  }

  send (message, callback) {
    container.jsonrpc.send(message, callback)
  }

  broadcast = (data) => {
    if (this.connection) {
      this.connection.broadcast(data)
    }
  }

  connect () {
    container.jsonrpc.connect()
  }

  destroy = () => {
    return this.connection.destroy()
  }
}
